// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import './profiles/styles/application';
import $ from 'jquery';
import 'slick-carousel';
import fitvids from 'fitvids';
import smoothscroll from 'smoothscroll-polyfill';

$(document).ready(function () {
  const EMAIL_REGEXP = /^\s*[\w\-+_]+(?:\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(?:\.[\w\-+_]+)*\s*$/;

  let $form = $('form#createLead');

  function formValues() {
    let firstName = $('#first-name-field').val();
    let lastName = $('#last-name-field').val();
    let email = $('#email-field').val();
    let message = $('#message-field').val();
    let inquiry = $(this).data('email-type') === 'inquiry';
    let subscriptionId = $('#subscription-id').val();

    return { firstName, lastName, email, message, inquiry, subscriptionId };
  }

  function validate() {
    let { firstName, lastName, email, message } = formValues();

    $('#error-banner').addClass('u-displayNone');

    // TODO: validate strings aren't too long?

    if (!firstName) {
      $('#first-name-field').addClass('is-invalid');
      $('#first-name-error').removeClass('u-displayNone');
    } else {
      $('#first-name-field').removeClass('is-invalid');
      $('#first-name-error').addClass('u-displayNone');
    }

    if (!lastName) {
      $('#last-name-field').addClass('is-invalid');
      $('#last-name-error').removeClass('u-displayNone');
    } else {
      $('#last-name-field').removeClass('is-invalid');
      $('#last-name-error').addClass('u-displayNone');
    }

    if (!email || !email.match(EMAIL_REGEXP)) {
      $('#email-field').addClass('is-invalid');
      $('#email-error').removeClass('u-displayNone');
    } else {
      $('#email-field').removeClass('is-invalid');
      $('#email-error').addClass('u-displayNone');
    }

    if (!message) {
      $('#message-field').addClass('is-invalid');
      $('#message-error').removeClass('u-displayNone');
    } else {
      $('#message-field').removeClass('is-invalid');
      $('#message-error').addClass('u-displayNone');
    }

    if (!firstName || !lastName || !email || !email.match(/@/) || !message) {
      return false;
    } else {
      return true;
    }
  }

  $('#first-name-field').focus(function () {
    $(this).removeClass('is-invalid');
    $('#first-name-error').addClass('u-displayNone');
  });

  $('#last-name-field').focus(function () {
    $(this).removeClass('is-invalid');
    $('#last-name-error').addClass('u-displayNone');
  });

  $('#email-field').focus(function () {
    $(this).removeClass('is-invalid');
    $('#email-error').addClass('u-displayNone');
  });

  $('#message-field').focus(function () {
    $(this).removeClass('is-invalid');
    $('#message-error').addClass('u-displayNone');
  });

  fitvids();
  smoothscroll.polyfill();

  $('.Button--menu, .Image--hexagonButton').click(function () {
    $('.Sidebar').toggleClass('is-open');
  });

  $form.submit(function (e) {
    e.preventDefault();

    if (validate()) {
      let { firstName, lastName, email, message, inquiry, subscriptionId } = formValues();
      let payload = {
        sales_profile_lead: {
          first_name: firstName,
          last_name: lastName,
          email,
          message,
          inquiry,
          subscription_id: subscriptionId,
        },
      };

      let xhr = $.post({
        url: '/api/v1/sales_profile_leads',
        contentType: 'application/json',
        data: JSON.stringify(payload),
      });

      xhr.done(function () {
        $('.SuperModal-inner').addClass('u-backgroundPrimary');
        $('.SuperModal-form').toggleClass('u-displayNone');
        $('.SuperModal-thankYou').toggleClass('u-displayNone');

        $form.find('input[type="text"], textarea').val('');

        if (window.analytics) {
          window.analytics.track('Contact Form Submitted');
        }
      });

      xhr.fail(function () {
        $('#error-banner').removeClass('u-displayNone');
      });
    }
  });

  let $superModalHeader = $('#super-modal-header');

  let $superModalPrompt = $('#super-modal-prompt');

  $('.Button--joinNow, .Button--cancelJoin, .email-me').click(function (e) {
    e.preventDefault();

    let inquiry = $(this).data('email-type') === 'inquiry';

    if (inquiry) {
      $superModalHeader.text("Have questions? I'd be happy to answer!");
      $superModalPrompt.text(
        'Enter your name and email address and and let me know how I can help you.'
      );
    } else {
      $superModalHeader.text('Looking forward to working with you!');
      $superModalPrompt.text(
        "Enter your name and email address and I'll send you instructions for getting started."
      );
    }

    $('.SuperModal').toggleClass('is-open');
  });

  $('a, button').click(function (evt) {
    if (window.analytics) {
      let text = evt.target.innerText;

      window.analytics.track('Click ' + text);
    }
  });

  $('.SuperModal-closeButton, .SuperModal-overlay').click(function () {
    $('.SuperModal').toggleClass('is-open');
    $('.SuperModal-inner').removeClass('u-backgroundPrimary');
    $('.SuperModal-form').removeClass('u-displayNone');
    $('.SuperModal-thankYou').addClass('u-displayNone');

    $('.Input, .Textarea').removeClass('is-invalid');
    $('.FormValidationError').addClass('u-displayNone');
  });

  $('.Button--nav').click(function () {
    let buttonText = $(this).attr('nav-target');
    let navHeight = $('nav').outerHeight();

    window.scroll({
      top: $(`.Section--${buttonText}`).position().top - navHeight,
      left: 0,
      behavior: 'smooth',
    });

    if ($(this).hasClass('Button--sidebar')) {
      $('.Sidebar').removeClass('is-open');
    }
  });

  $('.Icon--downArrow').click(function () {
    window.scroll({
      top: $('.Section--whatToExpect').position().top - 10,
      left: 0,
      behavior: 'smooth',
    });
  });

  $(window).resize(function () {
    if ($('.Sidebar').hasClass('is-open')) {
      $('.Sidebar').removeClass('is-open');
    }
  });

  $(document.body).mousedown(function (event) {
    var target = $(event.target);

    if (
      $('.Sidebar').hasClass('is-open') &&
      !target.parents().addBack().is('.Button--menu, .Image--hexagonButton') &&
      !target.parents().addBack().is('.Sidebar-inner')
    ) {
      $('.Sidebar').removeClass('is-open');
    }
  });

  $('.Carousel').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    adaptiveHeight: true,
    slide: '.Slide',
    rows: 0,
    nextArrow: '.Button--carouselRight',
    prevArrow: '.Button--carouselLeft',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  });

  $(window).scroll(function () {
    let y = $(window).scrollTop();
    let buffer = 10;
    let navHeight = $('nav').outerHeight();
    let meetCoachPos = $('.Section--meetCoach').position().top - navHeight - buffer;
    let howItWorksPos = $('.Section--howItWorks').position().top - navHeight - buffer;
    let section3Pos = 0;
    let section3Target = null;
    let section4Pos = 0;
    let section4Target = null;

    if (document.querySelectorAll('.Section--testimonials').length > 0) {
      let testimonialsPos = $('.Section--testimonials').position().top - navHeight - buffer;

      section3Pos = testimonialsPos;
      section3Target = '[nav-target="testimonials"]';
    }

    if (document.querySelectorAll('.Section--pricing').length > 0) {
      let pricingPos = $('.Section--pricing').position().top - navHeight - buffer;

      if (section3Pos == 0) {
        section3Pos = pricingPos;
        section3Target = '[nav-target="pricing"]';
      } else {
        section4Pos = pricingPos;
        section4Target = '[nav-target="pricing"]';
      }
    }

    let heroBottom =
      $('.Section--intro').position().top + $('.Section--intro').outerHeight() - navHeight;

    $('[nav-target]').removeClass('is-active');

    if (y < meetCoachPos) {
      $('[nav-target="intro"]').addClass('is-active');
    } else if (y > meetCoachPos && y < howItWorksPos) {
      $('[nav-target="meetCoach"]').addClass('is-active');
    } else if (y > howItWorksPos && (!section3Pos || y < section3Pos)) {
      $('[nav-target="howItWorks"]').addClass('is-active');
    } else if (section3Pos != 0 && y > section3Pos && (!section4Pos || y < section4Pos)) {
      $(section3Target).addClass('is-active');
    } else if (section4Pos != 0 && y > section4Pos) {
      $(section4Target).addClass('is-active');
    }

    if (y < heroBottom) {
      $('nav').removeClass('is-showing');
    } else {
      $('nav').addClass('is-showing');
    }
  });
});
